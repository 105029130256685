// src/controllers/hello_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    
  }

  onScroll(event) {
    var y = window.scrollY;

    if(y >= 115){
      document.getElementById('menu-sal').classList.add('menu-fixed');
    }
    else{
      document.getElementById('menu-sal').classList.remove('menu-fixed');
    }
  }

}