import { Controller } from 'stimulus'

import axios from 'axios'
import validator from 'validator'

export default class extends Controller {
  static targets = ['name', 'phone', 'email', 'message', 'alert']

  initialize () {}

  show_alert (show, message, type) {
    if (type == 'success') {
      this.alertTarget.style.backgroundColor = '#3c6043'
    }

    if (type == 'error') {
      this.alertTarget.style.backgroundColor = '#a90000'
    }

    if (type == 'warning') {
      this.alertTarget.style.backgroundColor = '#a90000'
    }

    if (show) {
      this.alertTarget.style.display = 'block'
      this.alertTarget.textContent = message
    } else {
      this.alertTarget.style.display = 'none'
      this.alertTarget.textContent = ''
    }
  }

  async sendEmail () {
    if (this.nameTarget.value == '') {
      this.show_alert(true, 'Por favor ingrese su nombre', 'warning')
      this.nameTarget.focus()
      return
    } else {
      this.show_alert(false, '', 'default')
    }

    if (this.phoneTarget.value == '') {
      this.show_alert(true, 'Por favor ingrese su teléfono', 'warning')
      this.phoneTarget.focus()
      return
    } else {
      this.show_alert(false, '', 'default')
    }

    if (this.emailTarget.value == '') {
      this.show_alert(
        true,
        'Por favor ingrese su correo electrónico',
        'warning'
      )
      this.emailTarget.focus()
      return
    } else {
      if (!validator.isEmail(this.emailTarget.value)) {
        this.show_alert(
          true,
          'Por favor ingrese un correo electrónico válido',
          'warning'
        )
        this.emailTarget.focus()
        return
      }
      this.show_alert(false, '', 'default')
    }

    if (this.messageTarget.value == '') {
      this.show_alert(true, 'Por favor ingrese un mensaje', 'warning')
      this.messageTarget.focus()
      return
    } else {
      this.show_alert(false, '', 'default')
    }

    let request_mail = {
      method: 'POST',
      //url :`http://localhost/Testing/saldejade_email.php`,
      url: 'http://integrattodev.cloudapp.net/SaldeJadeDev/mail_jade.php',
      data: {
        name: this.nameTarget.value,
        phone: this.phoneTarget.value,
        email: this.emailTarget.value,
        message: this.messageTarget.value,
      },
    }

    await axios(request_mail)
      .then(response => {
        console.log(response)
        if (response.data.code == 200) {
          this.show_alert(
            true,
            'Correo electrónico enviado con éxito',
            'success'
          )

          this.nameTarget.value = ''
          this.phoneTarget.value = ''
          this.emailTarget.value = ''
          this.messageTarget.value = ''

          setTimeout(() => {
            this.show_alert(false, '', 'default')
          }, 5000)
        } else {
          this.show_alert(
            true,
            'Algo ha salido mal, por favor intenta de nuevo',
            'error'
          )

          setTimeout(() => {
            this.show_alert(false, '', 'default')
          }, 5000)
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status == 500) {
            this.show_alert(
              true,
              'Algo ha salido mal, por favor intenta de nuevo',
              'error'
            )

            setTimeout(() => {
              this.show_alert(false, '', 'default')
            }, 5000)
          }
        }
      })
  }
}
