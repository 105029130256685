import { Controller } from 'stimulus'

export default class NavbarController extends Controller {
  static targets = ['navbarBurger', 'navMenu', 'mobileMenu']

  connect () {
    this.navbarBurgerTarget.addEventListener('click', () => {
      this.mobileMenuTarget.classList.toggle('is-hidden')
      this.navMenuTarget.classList.toggle('is-hidden-mobile')
      console.log("Hello, Stimulus!", this.element)
    })
  }
}
