import { Controller } from 'stimulus'

import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

export default class extends Controller {
  static targets = ['slider2']

  initialize () {
    this.initCarousel2()
  }

  initCarousel2 () {
    let scope = this

    let options = {
      centered: true,
      initial: 0,
      loop: true,
      mode: 'free-snap',
      slidesPerView: 1,
      spacing: 40,
      breakpoints: {
        '(min-width: 768px)': {
          slidesPerView: 2,
          mode: 'free-snap',
        },
        '(min-width: 1200px)': {
          slidesPerView: 3,
          mode: 'free-snap',
        },
      },
      created: function (instance) {
        setTimeout(() => {
          scope.updateClasses(instance)
        }, 100)
      },
      slideChanged (instance) {
        scope.updateClasses(instance)
      },
    }

    this.slider2 = new KeenSlider('#jadeslider2', options)

    let dots_wrapper = document.getElementById('dotsp')
    let slides = document.querySelectorAll('.slidep')
    slides.forEach((t, idx) => {
      let dot = document.createElement('button')
      dot.classList.add('dot')
      dots_wrapper.appendChild(dot)
      dot.addEventListener('click', function () {
        scope.slider2.moveToSlideRelative(idx)
      })
    })
  }

  updateClasses (instance) {
    let slide = instance.details().relativeSlide
    let dots = document.querySelectorAll('.dot')
    dots.forEach(function (dot, idx) {
      idx === slide
        ? dot.classList.add('dot--active')
        : dot.classList.remove('dot--active')
    })
  }
}
