import { Controller } from 'stimulus'
import { Loader, LoaderOptions } from 'google-maps'

export default class extends Controller {
  static targets = ['mapa']

  connect () {
    this.initMap()
  }

  async initMap () {
    let lat = 29.081385
    let lng = -110.97209

    const loader = new Loader(
      'AIzaSyDjnQHn9ietGIr11LVonR21YFQSn5uJKCg&libraries=places&v=weekly',
      {}
    )
    const google = await loader.load()

    const map = new google.maps.Map(document.getElementById('mapa'), {
      center: { lat, lng },
      zoom: 15,
      styles: [
        {
          elementType: 'geometry',
          stylers: [
            {
              color: '#fbfbfb',
            },
            {
              saturation: -100,
            },
          ],
        },
        {
          elementType: 'labels.icon',
          stylers: [
            {
              color: '#e1e4e8',
            },
            {
              visibility: 'off',
            },
          ],
        },
        {
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#e1e4e8',
            },
          ],
        },
        {
          featureType: 'administrative.land_parcel',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#e1e4e8',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#e1e4e8',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [
            {
              color: '#fbfbfb',
            },
          ],
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          featureType: 'road.arterial',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#e1e4e8',
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
            {
              saturation: -100,
            },
          ],
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#e1e4e8',
            },
          ],
        },
        {
          featureType: 'road.local',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#e1e4e8',
            },
          ],
        },
        {
          featureType: 'transit.line',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
            {
              saturation: -100,
            },
          ],
        },
        {
          featureType: 'transit.station',
          elementType: 'geometry',
          stylers: [
            {
              color: '#f5f5f5',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [
            {
              color: '#fbfbfb',
            },
          ],
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [
            {
              color: '#9e9e9e',
            },
          ],
        },
      ],
    })

    var coordinates_marker = new google.maps.LatLng(lat, lng)
    var imageicon = {
      url: '../assets/icon-map.png',
      scaledSize: new google.maps.Size(70, 75),
    }

    var marketOptions = {
      position: coordinates_marker,
      map: map,
      title: 'Sal De Jade',
      icon: imageicon,
    }

    new google.maps.Marker(marketOptions)
  }
}
