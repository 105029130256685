import { Controller } from 'stimulus'
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'

export default class CarouselController extends Controller {
  static targets = ['container']

  connect () {
    this.initSwiper()
  }

  initSwiper () {
    Swiper.use([Navigation, Pagination, Autoplay])

    const mySwiper = new Swiper(this.containerTarget, {
      direction: 'horizontal',
      slidesPerView: 1,
      hashNavigation: true,
      autoplay: {
        delay: 4000,
      },
    })
  }
}
