import { Controller } from 'stimulus'

import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

export default class extends Controller {
  static targets = ['slider']

  initialize () {
    this.initCarousel()
  }

  initCarousel () {
    let scope = this
    let options = {
      centered: false,
      initial: 0,
      loop: true,
      mode: 'free-snap',
      slidesPerView: 1,
      spacing: 30,
      breakpoints: {
        '(min-width: 768px)': {
          slidesPerView: 2,
          mode: 'free-snap',
        },
        '(min-width: 1200px)': {
          slidesPerView: 3,
          mode: 'free-snap',
        },
      },
      created: function (instance) {
        var dots_wrapper = document.getElementById('dots_slider3')
        var slides = document.querySelectorAll('.slider_dots_3')

        slides.forEach(function (t, idx) {
          var dot = document.createElement('button')
          dot.classList.add('dot_s3')
          dots_wrapper.appendChild(dot)

          dot.addEventListener('click', function () {
            instance.moveToSlide(idx)
          })
        })
        scope.updateClasses(instance)
      },
      slideChanged (instance) {
        scope.updateClasses(instance)
      },
    }
    this.slider = new KeenSlider('#jadeslider', options)
  }

  updateClasses (instance) {
    var slide = instance.details().relativeSlide
    var dots = document.querySelectorAll('.dot_s3')
    dots.forEach(function (dot, idx) {
      idx === slide
        ? dot.classList.add('dot--active')
        : dot.classList.remove('dot--active')
    })
  }

  next () {
    this.slider.next()
  }

  prev () {
    this.slider.prev()
  }

  next1 () {
    this.slider1.next()
  }

  prev1 () {
    this.slider1.prev()
  }
}
