import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['modal']

  showModal (event) {
    this.modalTarget.classList.add('is-active')
  }

  closeModal () {
    this.modalTarget.classList.remove('is-active')
  }
}
